import React, { useEffect, useState } from 'react'
import { Link } from '@reach/router'

const Post = ({ id }) => {
    const [post, setPost] = useState({})

    useEffect(() => {
        const getPost = async () => {
            const res = await fetch(
                `https://myworker.cloudflaresocial.workers.dev/posts/${id}`
            )

            const postRes = await res.json()
            setPost(postRes)
        }

        getPost()
    }, [id])

    if (!Object.keys(post).length) return <div />

    return (
        <div>
            <h1>{post.title}</h1>
            <p>{post.content}</p>
            <p>
                <em>Published {new Date(post.published).toLocaleString()}</em>
            </p>
            <p>
                <Link to="/">Go Back</Link>
            </p>
        </div>
    )
}

export default Post
