import React, { useEffect, useState } from 'react'
import { Link } from '@reach/router'

const Posts = () => {
    const [posts, setPosts] = useState([])

    useEffect(() => {
        const getPosts = async () => {
            const res = await fetch(
                'https://myworker.cloudflaresocial.workers.dev/posts'
            )

            const postsRes = await res.json()
            setPosts(postsRes)
        }

        getPosts()
    }, [])

    return (
        <div>
            <h1>Posts</h1>
            {posts.map((post) => (
                <div key={post.id}>
                    <h2>
                        <Link to={`/posts/${post.id}`}>{post.title}</Link>
                    </h2>
                </div>
            ))}
        </div>
    )
}

export default Posts
